import React from "react"
import Layout from '../components/layout'
import '../styles/blog.css'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { Container, Row, Col} from "react-bootstrap"

const BlogPage = (probs) => {
    const data = useStaticQuery(graphql`
    
query {
   
        allContentfulBlog (
          sort: {
            fields: publishedDate,
            order: DESC
          }
        )
        {
          edges {
            node {
              featuredImage {
                fluid {
                  src
                }
              }
              slug
              title
              publishedDate(formatString:"MMM DO, YYYY")
              author
            }
          }
        }


        blogBg: file(relativePath: {eq: "christina-deravedisian-m0odxYBcFSw-unsplash.jpg"}) {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100)
               {
                ...GatsbyImageSharpFluid 
              }
            }
          }
      }
  
  `)


    return (
        <Layout>
            <div className="B_first_section">
                <div
                    className="b_background_img"
                    >
                        <Container>
                            <Row>
                                <Col xl={6} lg={6} md={8}>
                                     <div className="B_vertical_line"></div> 
                                     <h1 className="head_blog">blog</h1>
                                     <p className="blog_caption">Sharing different perspectives and experiences about the fastest growing destination for medical tourism.</p>
                                 </Col>     
                            </Row>
                        </Container>

                
                </div>
                {/* <div class="B_btm_svg"></div> */}
            </div>

                <Container>
                    <Row>
                    {data.allContentfulBlog.edges.map(edge => {
                        return(
                                <Col xl={4} lg={4} md={6} sm={6} xs={12} className="b_cols d-flex justify-content-around">
                                    <Link to={`/blog/${edge.node.slug}/`}>
                                        
                                            <Img style={{minWidth:"250px",minHeight:"200px"}} 
                                            fluid={edge.node.featuredImage.fluid}/>
                                        
                                        
                                        <h5 className="figCaption">{edge.node.title}</h5>
                                            <p className="authorName">{edge.node.author}</p>
                                    </Link>
                                </Col>
                            )
                        }
                    )}

                    </Row>
                      
                    
                    
                </Container>
        </Layout>
    )
}
export default BlogPage;
